
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index3ZAheBoFWeMeta } from "/var/www/app.conexsolution.com/releases/39/pages/access-code/[access_code]/index.vue?macro=true";
import { default as _91kit_id_9339HTjWf5fOMeta } from "/var/www/app.conexsolution.com/releases/39/pages/access-code/[access_code]/kit/[kit_id].vue?macro=true";
import { default as indexP5p5nG50JmMeta } from "/var/www/app.conexsolution.com/releases/39/pages/access-code/[access_code]/kit/index.vue?macro=true";
import { default as indexQxze7WRvkRMeta } from "/var/www/app.conexsolution.com/releases/39/pages/access-code/index.vue?macro=true";
import { default as _91access_code_9386ntRHMH7JMeta } from "/var/www/app.conexsolution.com/releases/39/pages/check-connectivity/[access_code].vue?macro=true";
import { default as index9dxRX5J3FqMeta } from "/var/www/app.conexsolution.com/releases/39/pages/check-connectivity/index.vue?macro=true";
import { default as indexKrXrlHVzYhMeta } from "/var/www/app.conexsolution.com/releases/39/pages/create-survey/index.vue?macro=true";
import { default as indexHscvGIFzZBMeta } from "/var/www/app.conexsolution.com/releases/39/pages/index.vue?macro=true";
export default [
  {
    name: "access-code-access_code",
    path: "/access-code/:access_code()",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/access-code/[access_code]/index.vue")
  },
  {
    name: "access-code-access_code-kit-kit_id",
    path: "/access-code/:access_code()/kit/:kit_id()",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/access-code/[access_code]/kit/[kit_id].vue")
  },
  {
    name: "access-code-access_code-kit",
    path: "/access-code/:access_code()/kit",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/access-code/[access_code]/kit/index.vue")
  },
  {
    name: "access-code",
    path: "/access-code",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/access-code/index.vue")
  },
  {
    name: "check-connectivity-access_code",
    path: "/check-connectivity/:access_code()",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/check-connectivity/[access_code].vue")
  },
  {
    name: "check-connectivity",
    path: "/check-connectivity",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/check-connectivity/index.vue")
  },
  {
    name: "create-survey",
    path: "/create-survey",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/create-survey/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/app.conexsolution.com/releases/39/pages/index.vue")
  }
]